import { RELATIONSHIP_GROUP_MEMBER_LIMIT } from "@thrive-web/ui-constants";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { GroupConnectionScoreSidebar } from "~/view/components";
import {
  asSubroute,
  SidebarSection,
  PageSidebar,
  GROUP_GROUP,
  GroupHealthGraph,
} from "@thrive-web/ui-components";
import { GroupSidebarTodos } from "../Todos/GroupSidebarTodos";

const create_group_sidebar_sections = (group: Group): Preact.VNode[] => {
  const items: Preact.VNode[] = [];
  if (group.has_relationship_management) {
    items.push(
      <SidebarSection key="connection-score" name="Group Connection Score">
        <GroupConnectionScoreSidebar
          className="card"
          score={group.score || 0}
          showLevelUpAnim={true}
        />
      </SidebarSection>
    );
  }
  if (
    group.has_member?.length &&
    group.has_member.length > 1 &&
    // hide the graph if there are too many members
    group.has_member.length <= RELATIONSHIP_GROUP_MEMBER_LIMIT
  ) {
    items.push(
      <SidebarSection
        key="health"
        name="Relationship Health"
        // moreLink: <AppLink href={`${url}/members`}>View All</AppLink>,
      >
        <GroupHealthGraph group={group} />
      </SidebarSection>
    );
  }
  if (group.has_goals) {
    items.push(<GroupSidebarTodos key="goals" group={group} />);
  }
  return items;
};

export const GroupSidebarBase: Preact.FunctionComponent<RoutePageProps> =
  () => {
    const group = useContext(GROUP_GROUP);
    const items = useMemo(
      () => (group ? create_group_sidebar_sections(group) : []),
      [group]
    );
    return <PageSidebar className="group-sidebar">{items}</PageSidebar>;
  };

export const GroupSidebar = asSubroute(GroupSidebarBase);
