import * as Preact from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import { createPortal, forwardRef } from "preact/compat";
import { media_url } from "@thrive-web/core";
import { entity_has_type } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  CompoundButton,
  DefaultModalContent,
  defaultUserListEmptyLabel,
  DivWithIcon,
  Icon,
  Popover,
  ScopeItem,
  SelectUserModal,
  usePopoverTrigger,
  UserInviteListItem,
  UserSearchFn,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useId,
  useRenderPropsFunction,
} from "@thrive-web/ui-hooks";
import { display_text, maybeClassName } from "@thrive-web/ui-utils";
import { Mood, User } from "@thrive-web/ui-api";

export const PostAddPhotoButton: Preact.FunctionComponent<
  FileUploadButtonProps & { clearInput: EventListener }
> = ({ className, file, clickInput, clearInput, id }) => {
  let preview: any = null;
  // if a file is selected, show the image preview instead of the upload button
  if (file) {
    const node = document.getElementById("post-form-photo-preview");
    if (node instanceof HTMLElement) {
      preview = createPortal(
        <PostPhotoPreview file={file} clearFile={clearInput} />,
        node
      );
    }
    return preview;
  }
  return (
    <ButtonWithIcon
      id={id}
      className={`filled gray${maybeClassName(className)}`}
      icon="camera-2"
      onClick={clickInput}
      side="left"
    >
      Add a Photo
      {preview}
    </ButtonWithIcon>
  );
};

export const PostPhotoPreview: Preact.FunctionComponent<{
  file: { name: string; data: string };
  clearFile: EventListener;
}> = ({ file, clearFile }) => (
  <div className="post__form__photo-preview">
    <ButtonWithIcon
      className="filled gray post__form__photo-preview__remove"
      icon="remove"
      side="right"
      collapse={true}
      onClick={clearFile}
    >
      Remove
    </ButtonWithIcon>
    <img src={file.data} alt={file.name} />
  </div>
);

export const PostFormHelpBlock: Preact.FunctionComponent<{
  user: User;
  remove: () => void;
}> = ({ user, remove }) => (
  <DivWithIcon
    icon="helping-hands"
    side="left"
    className="post__help post-block"
    iconOpposite={
      <button className="filled gray all-gray" type="button" onClick={remove}>
        <Icon name="remove" />
      </button>
    }
  >
    {user.first_name} is asking for help!
  </DivWithIcon>
);

export const PostTagUsersButton = forwardRef<
  HTMLButtonElement,
  { count?: number } & HTMLButtonProps
>(({ className, count = 0, ...props }, ref) => {
  return (
    <ButtonWithIcon
      ref={ref}
      className={`filled gray${maybeClassName(className)}`}
      {...props}
      icon="connection-request"
      side="left"
    >
      {count > 1 ? `${count} People` : count > 0 ? `1 Person` : "Tag Someone"}
    </ButtonWithIcon>
  );
});

export const PostFormTagUsers: Preact.FunctionComponent<{
  tagged: readonly User[];
  toggleUser: (user: User, is_tagged: boolean) => void;
  getUsers: UserSearchFn;
}> = ({ tagged, toggleUser, getUsers }) => {
  const app_user = useAppUser();
  const tagged_map = useMemo(() => {
    if (!tagged) {
      return {};
    }
    const map: any = {};
    tagged.forEach(u => {
      map[u.id] = true;
    });
    return map;
  }, [tagged]);

  const getUsersExceptSelf = useCallback<UserSearchFn>(
    (...args) =>
      getUsers(...args).then(response => {
        if (response.data) {
          response.data = response.data.filter(u => u.id !== app_user?.id);
        }
        return response;
      }),
    [getUsers, app_user?.id]
  );

  const renderUser = useRenderPropsFunction(
    (user: User) =>
      user.id === app_user?.id ? null : (
        <UserInviteListItem
          user={user}
          button={
            <ButtonWithIcon
              icon={tagged_map[user.id] ? "remove" : "connection-request"}
              side="left"
              className="filled gray"
              onClick={() => toggleUser(user, !!tagged_map[user.id])}
              data-state={tagged_map[user.id] ? "remove" : "tag"}
            >
              {tagged_map[user.id] ? "Remove" : "Tag"}
            </ButtonWithIcon>
          }
        />
      ),
    "PostTagUser-SearchItem",
    [tagged_map, toggleUser]
  );

  const emptyLabel = useMemo(
    () =>
      defaultUserListEmptyLabel("There are no connections available to add."),
    []
  );

  if (!app_user) {
    return null;
  }

  return (
    <SelectUserModal
      className="modal__body"
      getUsers={getUsersExceptSelf}
      renderUser={renderUser}
      emptyLabel={emptyLabel}
    />
  );
};

export const PostFormTagUsersPage: Preact.FunctionComponent<{
  tagged: readonly User[];
  toggleUser: (user: User, is_tagged: boolean) => void;
  getUsers: UserSearchFn;
  finish: () => void;
}> = ({ finish, ...props }) => (
  <DefaultModalContent
    title={
      <Preact.Fragment>
        <ButtonWithIcon
          className="modal-form__back"
          icon="chevron-left"
          onClick={finish}
        />
        Tagged People
      </Preact.Fragment>
    }
    footer={
      <div className="modal__footer invite-users-modal__footer">
        <ButtonWithIcon
          icon="checked"
          side="right"
          className="button filled gray"
          onClick={finish}
        >
          Done
        </ButtonWithIcon>
      </div>
    }
  >
    <PostFormTagUsers {...props} />
  </DefaultModalContent>
);

export const PostFormMoodButton: Preact.FunctionComponent<{
  mood: Mood;
  onClear: (mood: any) => void;
}> = ({ mood, onClear }) => {
  return (
    <CompoundButton
      className="post__form__mood__button with-icon__compound pill"
      items={[
        <DivWithIcon
          className="pill filled gray static"
          icon={
            <span className="post__mood__emoji">
              {display_text(mood.symbol)}
            </span>
          }
          side="left"
        >
          Feeling <strong>{mood.title}</strong>
        </DivWithIcon>,
        <ButtonWithIcon
          className="pill filled gray"
          icon="remove"
          onClick={() => onClear(null)}
        />,
      ]}
    />
  );
};

export const PostDisabledFeature: Preact.FunctionComponent<{
  returnToMain: () => void;
}> = ({ returnToMain }) => (
  <DefaultModalContent
    title="Feature Disabled"
    footer={
      <div className="modal__footer modal__footer__left">
        <ButtonWithIcon
          icon="chevron-left"
          side="left"
          className="button filled gray"
          onClick={returnToMain}
        >
          Back
        </ButtonWithIcon>
      </div>
    }
  >
    <h4>This feature is not enabled for this group!</h4>
  </DefaultModalContent>
);

export const PostSetScopeButton: Preact.FunctionComponent<{
  scope: ScopeItem;
  allowGroupChange: boolean;
  showWarning: boolean;
  onClick: () => void;
}> = ({ scope, allowGroupChange, showWarning, onClick }) => {
  const id = useId("group-change-button");
  const [open, set_open, trigger_props] = usePopoverTrigger(
    { click: true, focus: false, hover: false },
    id
  );

  const tooltip_source_ref = useRef<HTMLDivElement>(null);
  const tooltip_props = useMemo<Partial<PopoverProps>>(
    () => ({
      getSourceRef: () => tooltip_source_ref.current,
      defaultDirection: "bottom",
    }),
    [tooltip_source_ref]
  );

  const img_url = useMemo(
    () =>
      entity_has_type(scope, "Community")
        ? media_url<"Community", "cover_image">(scope, "cover_image", "small")
        : undefined,
    [scope]
  );

  const button = (
    <ButtonWithIcon
      {...(allowGroupChange
        ? {
            ...(showWarning ? trigger_props : { onClick }),
            className: "pill filled gray post__group",
          }
        : {
            className: "pill filled gray static post__group",
            tabIndex: -1,
          })}
      icon={
        img_url ? (
          <img src={img_url} />
        ) : // @ts-expect-error:
        scope.background_color ? (
          <div
            ref={tooltip_source_ref}
            className="group__dot"
            style={{
              backgroundColor:
                // @ts-expect-error:
                scope.background_color,
            }}
          />
        ) : (
          <Preact.Fragment />
        )
      }
      side="left"
      iconOpposite={allowGroupChange ? "edit" : undefined}
      data-type={entity_has_type(scope, "Group") ? "group" : "community"}
    >
      {scope.name}
    </ButtonWithIcon>
  );

  if (!showWarning) {
    return <div className="post__form__scope">{button}</div>;
  }

  return (
    <div className="post__form__scope">
      <Popover id={id} show={open} triggerComponent={button} {...tooltip_props}>
        <div className="group__scope__change-warning">
          <h5>
            Warning: Changing the group will remove the Tagged Users, Group
            Update, and Reimbursement from the post.
          </h5>
          <div className="group__scope__change-warning__buttons">
            <button
              className="bordered"
              type="button"
              onClick={() => set_open(false)}
            >
              Cancel
            </button>
            <button className="filled gray" type="button" onClick={onClick}>
              Continue
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
};
