import { ApiMethodParameters } from "@thrive-web/core";
import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { Group, User } from "@thrive-web/ui-api";
import { add_item_to, remove_item_from } from "@thrive-web/ui-common";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  asSubroute,
  useSelfConnections,
  ButtonWithIcon,
  UserListItem,
  useDynamicList,
  DeleteModalBody,
  DefaultModalContent,
  DropdownMenu,
  DefaultDropdownButtonDiv,
  LinkWithIcon,
  UserCard,
  SendSkeletonInviteButton,
  SendSkeletonLinkButton,
  SKELETON_INVITE_LINK_CTX,
  SkeletonLinkProvider,
  GROUP_DETAIL_LIST_CONTEXTS,
  GROUP_GROUP,
  UserContactLinkWithIcon,
  useLazyList,
  LazyListSection,
  useRenderDynamicListWithPagedFetch,
  ViewMoreLoader,
  GROUP_DISPATCH,
  RequestButtonWithIcon,
  FooterError,
  GroupPermCheck,
  SkeletonListLinkProvider,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useDocumentTitle,
  useDynamicListVariable,
  useLocalPagination,
  useModal,
  useRenderPropsFunction,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  format_time_passed,
  get_guid_from_iri,
  get_url_for_entity,
  make_title,
  maybeClassName,
  user_is_group_admin,
} from "@thrive-web/ui-utils";
import { GroupMemberInviteModalBody } from "./GroupMemberInviteModal";

import { ScreenSize } from "@thrive-web/ui-constants";
const group_not_loaded = {} as Group;

const GroupMembers: Preact.FunctionComponent<RoutePageProps> = () => {
  const group = useContext(GROUP_GROUP) || group_not_loaded;
  const update_group = useContext(GROUP_DISPATCH);
  useDocumentTitle(
    () => make_title(group?.name ? [`Members of ${group.name}`] : ["Groups"]),
    [group?.name]
  );
  const self = useAppUser();
  const [members, updateMembers] = useDynamicList(
    GROUP_DETAIL_LIST_CONTEXTS.members
  );

  // get connections so we can display connection health rings around avatars
  const [conns, refresh_conns] = useSelfConnections(self);
  // whenever a member is added or removed, refresh the connections list
  useEffect(() => {
    if (
      members &&
      members.length > 0 &&
      members?.length === group?.has_member?.length
    ) {
      refresh_conns();
    }
  }, [members && members?.length === group?.has_member?.length]);

  // separate lists for non-admin members and admins
  const [normal, admins] = useMemo(() => {
    const admins_: (User & { health?: number })[] = [];
    const normal_: (User & { health?: number })[] = [];
    if (!members) {
      return [null, admins_];
    }
    members?.forEach(u => {
      // add health score to each user, if connected
      const member = {
        ...u,
        health: conns?.find(c => c.other_user.id === u.id && !!c.has_coach)
          ?.health_tier,
      };
      if (user_is_group_admin(u, group)) {
        admins_.push(member);
      } else {
        normal_.push(member);
      }
    });
    return [normal_, admins_];
  }, [members, group, conns]);

  const onCloseModal = useCallback(() => {
    if (window.location.hash === "#invite") {
      window.history.replaceState(undefined, "", window.location.pathname);
    }
  }, []);
  const [memberInviteModal, setOpen] = useModal(
    {
      body: GroupMemberInviteModalBody,
      id: "group-member-invite",
      className: "group-members__modal modal-form",
    },
    onCloseModal
  );

  // handle the delete modal here rather than having a modal for each list item
  const [delete_target, set_delete_target] = useStateIfMounted<User | null>(
    null
  );
  const removeMemberReq = useApiMethod("removeGroupMember");
  const removeMember = useCallback(() => {
    if (!delete_target) {
      return Promise.resolve();
    }
    return removeMemberReq(group.id, {
      body: { data: [{ id: delete_target.id }] },
    }).then(res => {
      analytics.log_event(analytics.EVENTS.group_remove_user);
      return res;
    });
  }, [removeMemberReq, group.id, delete_target]);

  const afterRemove = useCallback(() => {
    set_delete_target(null);
    delete_target && updateMembers.remove(m => m.id === delete_target.id);
  }, [delete_target, set_delete_target, updateMembers]);
  const RemoveMemberModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) =>
      delete_target ? (
        <DefaultModalContent
          title="Remove Group Member"
          closeButton={closeButton}
        >
          <DeleteModalBody
            deleteRecord={removeMember}
            afterDelete={afterRemove}
            {...props}
          >
            <div>
              <UserCard user={delete_target} size="xl" />
            </div>
            Are you sure you want to remove this member from the group?
          </DeleteModalBody>
        </DefaultModalContent>
      ) : null,
    "GroupRemoveMemberModal-Bound",
    [self, removeMember, delete_target, afterRemove]
  );

  const [removeMemberModal, openRemoveMemberModal] = useModal({
    id: "remove-member-modal",
    innerClassName: "card card-stack modal-form group-members__remove__modal",
    body: RemoveMemberModalBody,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
  });

  const onClickRemove = useCallback(
    (user: User) => {
      set_delete_target(user);
      openRemoveMemberModal(true);
    },
    [set_delete_target, openRemoveMemberModal]
  );
  const is_admin = useMemo(
    () => (self && group ? user_is_group_admin(self, group) : false),
    [self, group]
  );

  const [admin_target, set_admin_target] = useStateIfMounted<User | null>(null);
  // when changing user role
  const on_change_admin = useCallback(
    (user: User) => {
      let new_admins = group.has_admin;
      if (!new_admins) {
        return;
      }
      if (user_is_group_admin(user, group)) {
        new_admins = remove_item_from(new_admins, u => u.id === user.id);
      } else {
        new_admins = add_item_to(new_admins, user);
      }
      update_group("group", {
        ...group,
        has_admin: new_admins,
      });
    },
    [group.has_admin, update_group]
  );

  const admin_modal_props = useMemo(
    () => ({
      user: admin_target,
      isAdmin: admin_target ? user_is_group_admin(admin_target, group) : false,
      onFinish: on_change_admin,
      groupId: group.id,
    }),
    [admin_target, group, on_change_admin]
  );
  const [memberAdminModal, openMemberAdminModal] = useModal({
    id: "member-admin-modal",
    innerClassName: "card card-stack modal-form group-members__admin__modal",
    body: GroupMemberAdminModal,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
    bodyProps: admin_modal_props,
  });
  const open_admin_modal = useCallback(
    (user: User) => {
      set_admin_target(user);
      openMemberAdminModal(true);
    },
    [openMemberAdminModal]
  );

  useEffect(() => {
    if (window.location.hash === "#invite") {
      setOpen(true);
    }
  }, []);

  // params for getting existing registration invites to skeleton users
  const reg_invite_params = useMemo<ApiMethodParameters<
    "GET",
    "RegistrationInvitation"
  > | null>(
    () =>
      group.id && !!(group.has_member || members)
        ? {
            query: {
              filter: [
                [
                  "=",
                  [
                    "this",
                    [
                      "/",
                      "RegistrationInvitation:recipient",
                      ["^", "Group:has_member"],
                    ],
                  ],
                  ["id", group.id],
                ],
              ],
              limit: (group.has_member?.length || members?.length) ?? 1,
            },
          }
        : null,
    [group.id, group.has_member?.length || members?.length]
  );

  // dynamic list for non-admin members
  const [normal_dyn, set_normal] = useDynamicListVariable<
    User & { health?: number }
  >(null);
  const get_normal_paged = useLocalPagination(normal);

  const passthrough_props = useMemo(
    () => ({
      isAdmin: is_admin,
      onClickRemove,
      refreshConnections: refresh_conns,
      onChangeAdmin: open_admin_modal,
    }),
    [is_admin, onClickRemove, refresh_conns, open_admin_modal]
  );

  const content = useRenderDynamicListWithPagedFetch(
    normal_dyn,
    set_normal,
    (result, load_more_elem, pending, passthrough) => (
      <GroupMembersList
        title="Members"
        users={result}
        loadMoreElem={load_more_elem}
        adminList={false}
        inviteParams={reg_invite_params}
        {...passthrough!}
      />
    ),
    [reg_invite_params],
    get_normal_paged,
    passthrough_props,
    {
      limit: 15,
      LoadMoreComponent: ViewMoreLoader,
    }
  );

  if (!self || !group) {
    return null;
  }
  return (
    <div className="page-tab group-people-page">
      <div className="page-tab__top">
        {(group.can_members_invite || is_admin) && (
          <ButtonWithIcon
            icon="add"
            side="left"
            className="filled gray"
            onClick={() => setOpen(true)}
          >
            Invite a Member
          </ButtonWithIcon>
        )}
      </div>
      <GroupMembersList
        title="Group Admin"
        users={admins}
        loadMoreElem={null}
        isAdmin={is_admin}
        adminList={true}
        onChangeAdmin={open_admin_modal}
        onClickRemove={onClickRemove}
        refreshConnections={refresh_conns}
        inviteParams={null}
      />

      {members ? content : null}
      {memberInviteModal}
      {removeMemberModal}
      {memberAdminModal}
    </div>
  );
};

export const GroupMembersPage = asSubroute(GroupMembers);

export const GroupMembersList: Preact.FunctionComponent<{
  title?: Preact.ComponentChildren;
  users: readonly (User & { health?: number })[];
  loadMoreElem: Preact.VNode | null;
  isAdmin: boolean;
  adminList: boolean;
  onClickRemove: (user: User) => void;
  onChangeAdmin: (user: User) => void;
  refreshConnections: () => void;
  inviteParams: ApiMethodParameters<"GET", "RegistrationInvitation"> | null;
}> = ({
  title,
  users,
  isAdmin,
  adminList,
  onClickRemove,
  onChangeAdmin,
  refreshConnections,
  loadMoreElem,
  inviteParams,
}) => {
  const window_size = useContext(CONTEXTS.window_size);
  const screen_is_small = window_size < ScreenSize.sm;

  const list = useLazyList(
    users,
    u => {
      const remove_button = isAdmin ? (
        <GroupMemberRemoveButton
          user={u}
          onRemove={onClickRemove}
          className={screen_is_small ? "pill negative" : "gray"}
        />
      ) : undefined;
      return (
        <UserListItem
          linkBox="content"
          user={u}
          key={u.id}
          size="sm"
          health={u.health}
        >
          <GroupPermCheck role="has_member">
            {!screen_is_small && remove_button}
            {!u.firebase_uuid ? (
              <SkeletonLinkProvider user={u}>
                <GroupMemberDropdown
                  user={u}
                  removeButton={screen_is_small ? remove_button : undefined}
                />
              </SkeletonLinkProvider>
            ) : (
              <GroupMemberDropdown
                user={u}
                onCreateInteraction={refreshConnections}
                removeButton={screen_is_small ? remove_button : undefined}
                adminButton={
                  isAdmin ? (
                    <GroupMemberAdminButton
                      user={u}
                      isAdmin={adminList}
                      onClick={onChangeAdmin}
                    />
                  ) : undefined
                }
              />
            )}
          </GroupPermCheck>
        </UserListItem>
      );
    },
    [onClickRemove, screen_is_small, isAdmin, refreshConnections]
  );

  if (users.length === 0) {
    return null;
  }

  const inner_content = (
    <Preact.Fragment>
      {list.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && (
        <div className="user-list__load-more">{loadMoreElem}</div>
      )}
    </Preact.Fragment>
  );

  const content = inviteParams ? (
    <SkeletonListLinkProvider params={inviteParams}>
      {inner_content}
    </SkeletonListLinkProvider>
  ) : (
    inner_content
  );

  return (
    <div className="page-tab__section">
      <div className="page-tab__section__title">{title}</div>
      <div className="page-tab__record-list">{content}</div>
    </div>
  );
};

const GroupMemberRemoveButton: Preact.FunctionComponent<
  MaybeClass & {
    user: User;
    onRemove: (user: User) => void;
  }
> = ({ className, user, onRemove }) => {
  return (
    <ButtonWithIcon
      icon="remove"
      side="left"
      className={`filled${maybeClassName(className)}`}
      onClick={() => onRemove(user)}
    >
      Remove{className === "pill negative" ? " From Group" : ""}
    </ButtonWithIcon>
  );
};

const GroupMemberAdminButton: Preact.FunctionComponent<{
  user: User;
  isAdmin: boolean;
  onClick: (user: User) => void;
}> = ({ user, isAdmin, onClick }) => (
  <ButtonWithIcon
    icon={isAdmin ? "thumbs-down-solid" : "thumbs-up-solid"}
    side="left"
    className="filled gray pill"
    onClick={() => onClick(user)}
  >
    {isAdmin ? "Remove" : "Make"} Group Admin
  </ButtonWithIcon>
);

export const GroupMemberAdminModal: Preact.FunctionComponent<
  ModalBodyProps & {
    user: User;
    onFinish: (user: User) => void;
    isAdmin: boolean;
    groupId: string;
  }
> = ({ closeButton, dismiss, user, onFinish, isAdmin, groupId }) => {
  const add_group_admin = useApiMethod("addGroupAdmin");
  const remove_group_admin = useApiMethod("removeGroupAdmin");

  const change_admin = useCallback(
    () =>
      (isAdmin ? remove_group_admin : add_group_admin)(groupId, {
        body: { data: [{ id: user.id }] },
      }).then(res => {
        analytics.log_event(
          analytics.EVENTS[isAdmin ? "group_remove_admin" : "group_make_admin"]
        );
        onFinish(user);
        setTimeout(dismiss, 1200);
        return res;
      }),
    [isAdmin, groupId, user.id]
  );

  const [on_confirm, { pending, success, error }] = useRequest(change_admin);

  return (
    <DefaultModalContent
      title={`${isAdmin ? "Remove" : "Make"} Group Admin`}
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div className="modal__footer__left">
            <button className="filled gray" onClick={dismiss}>
              Cancel
            </button>
          </div>
          <div className="modal__footer__right">
            <FooterError error={error} />
            <RequestButtonWithIcon
              pending={pending}
              icon="checked"
              side="left"
              className="filled gray"
              success={success}
              successText="Success"
              onClick={on_confirm}
            >
              Confirm
            </RequestButtonWithIcon>
          </div>
        </div>
      }
    >
      <div className="group-members__admin__modal__body">
        <UserCard user={user} size="xl" />
        <h5>
          Do you want to{" "}
          {isAdmin ? `remove ${user.full_name} as` : `make ${user.full_name}`}{" "}
          an admin of this group?
        </h5>
      </div>
    </DefaultModalContent>
  );
};

const GroupMemberDropdown: Preact.FunctionComponent<{
  removeButton?: Preact.VNode;
  adminButton?: Preact.VNode;
  user: User;
  onCreateInteraction?: () => void;
}> = ({ removeButton, adminButton, user, onCreateInteraction }) => {
  const id = useMemo(() => {
    const [guid] = get_guid_from_iri(user.id);
    return `group-member-dropdown-${guid}`;
  }, [user.id]);
  const { link } = useContext(SKELETON_INVITE_LINK_CTX);

  const dropdown_btn_ref = useRef<HTMLElement | null>(null);
  const get_btn_ref = useCallback(
    () => dropdown_btn_ref.current,
    [dropdown_btn_ref]
  );

  let items: any[] = [];

  // if not skeleton user, show profile link
  if (user.firebase_uuid) {
    items.unshift(
      <LinkWithIcon
        icon="profile"
        side="left"
        className="filled pill button gray"
        href={get_url_for_entity(user)}
      >
        View Profile
      </LinkWithIcon>,
      <UserContactLinkWithIcon
        icon="email"
        side="left"
        className="filled pill button gray"
        type="email"
        user={user}
        onCreateInteraction={onCreateInteraction}
        href={`mailto:${user.email}`}
      >
        Email: {user.email}
      </UserContactLinkWithIcon>
    );
  } else {
    // if skeleton user, show invite links and contact link without interaction tracking
    items.unshift(
      <SendSkeletonInviteButton user={user} />,
      <SendSkeletonLinkButton user={user} />,
      <div className="dropdown__divider" />,
      <LinkWithIcon
        icon="email"
        side="left"
        className="filled pill button gray"
        href={`mailto:${user.email}`}
      >
        Email: {user.email}
      </LinkWithIcon>
    );
  }

  const invite_date = useMemo(() => {
    if (!user.firebase_uuid && link && link.link && link.created_at) {
      const str = format_time_passed(new Date(link.created_at), true, true);
      if (str === `Just now`) {
        return `Last invite sent ${str.toLowerCase()}`;
      }
      return `Last invite sent ${str}${str.length < 5 ? " ago" : ""}`;
    }
  }, [user.firebase_uuid, link?.link, link?.created_at]);

  items.unshift(
    <UserCard user={user} size="xl">
      {invite_date}
    </UserCard>
  );

  if (adminButton || removeButton) {
    items.push(<div className="dropdown__divider" />);
  }
  if (adminButton) {
    items.push(adminButton);
  }
  if (removeButton) {
    items.push(removeButton);
  }

  const popover_props = useMemo(
    () => ({
      getSourceRef: get_btn_ref,
    }),
    [get_btn_ref]
  );

  return (
    <DropdownMenu
      id={id}
      className="group-members__dropdown"
      listClassName="card pill-card"
      items={items}
      popoverProps={popover_props}
      buttonClassName="non-button"
      button={
        <DefaultDropdownButtonDiv
          icon="more"
          className="button filled transparent all-gray"
          ref={dropdown_btn_ref}
        />
      }
      closeOnClickItem={false}
    />
  );
};
