import * as Preact from "preact";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { Notification } from "@thrive-web/ui-api";
import {
  NotificationListItem,
  NotificationMenuLoading,
} from "~/view/components";
import {
  EmptyList,
  Icon,
  Popover,
  useAsyncRenderResult,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import { useNotificationList } from "./utils";

export const NotificationMenu: Preact.FunctionComponent = () => {
  const icon_ref = useRef<HTMLElement>();
  const pass_icon_ref = useCallback(() => icon_ref.current, [icon_ref]);

  const [open, setOpen, listeners] = usePopoverTrigger(
    { click: true, focus: false, hover: false },
    "notif-menu"
  );
  const closeMenu = useCallback(() => setOpen(false), [setOpen]);

  const [list, , get_notifs, status, read_notif, on_update, unread, show_new] =
    useNotificationList(true, open, closeMenu);
  const notifs = useMemo(() => list?.slice(0, 3) || null, [list]);

  const first_success = useRef(false);
  if (!first_success.current && status.success) {
    first_success.current = true;
  }
  const passthrough = useMemo(
    () => ({
      read_notif,
      on_update,
      show_new,
      open,
    }),
    [read_notif, on_update, show_new, open]
  );

  const content = useAsyncRenderResult(
    (results: Notification[], pending, passthrough) => (
      <ul className="notif-menu__list">
        {passthrough!.show_new && (
          <li className="notif-menu__list__show-new">
            <button
              className="plain-link"
              onClick={e => {
                if (passthrough!.show_new) {
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  passthrough!.show_new();
                }
              }}
            >
              View new notifications
            </button>
          </li>
        )}
        {results.length === 0 && (
          <EmptyList>
            <p>You currently have no notifications.</p>
          </EmptyList>
        )}
        {results.map(n => (
          <NotificationListItem
            key={n.id}
            item={n}
            onClick={passthrough!.read_notif}
            onUpdate={passthrough!.on_update}
            visible={passthrough!.open}
            isMenu={true}
          />
        ))}
      </ul>
    ),
    [],
    status,
    first_success.current ? notifs : undefined,
    true,
    undefined,
    NotificationMenuLoading,
    false,
    passthrough
  );

  useEffect(() => {
    get_notifs(0, 15);
  }, []);

  return (
    <Popover
      id="notif-menu"
      className="notif-menu"
      triggerComponent={
        <button
          {...listeners}
          className="filled transparent site-userbar__activity site-userbar__item"
          data-open={open}
          data-badge={unread ? "unreads" : undefined}
        >
          <span>Notifications</span>
          <Icon name="activity" ref={icon_ref} />
        </button>
      }
      show={open}
      getSourceRef={pass_icon_ref}
      forceDirection="left"
      forceOffset="top"
      animation="fade"
      mountLocal={true}
    >
      <fieldset disabled={!open} className="notif-menu__content">
        <h4 className="notif-menu__header">Latest</h4>
        {content}
        <a
          className="notif-menu__more"
          tabIndex={open ? undefined : -1}
          onClick={closeMenu}
          href="/notifications"
        >
          See All Notifications
        </a>
      </fieldset>
    </Popover>
  );
};
