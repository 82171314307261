import * as Preact from "preact";
import { capitalize } from "@thrive-web/ui-common";
import { DEFAULT_GROUP_BACKGROUND_COLOR } from "~/view/components";
import {
  AvatarListLoading,
  LinkWithIcon,
  PageSidebar,
  SidebarSection,
  useAsyncRenderResult,
  useFetchGroups,
  UserListItem,
  UserListItemLoading,
  GROUPS,
  PermCheck,
} from "@thrive-web/ui-components";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { ConnectionMapped, Group } from "@thrive-web/ui-api";
import {
  useApiMethod,
  useAppUser,
  useCallbackRef,
  useControlledRequest,
  useMedia,
  useRequest,
  useSingleClickDetection,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import {
  cache_record,
  display_text,
  get_url_for_entity,
  hex_to_rgb,
  connection_coach_filter,
} from "@thrive-web/ui-utils";
import { route } from "preact-router";

const SIDEBAR_GROUP_LIST_LIMIT = 5;
const SIDEBAR_CONN_LIST_LIMIT = 5;

export const HomeSidebar: Preact.FunctionComponent = () => {
  const user = useAppUser();
  const { list, dispatch } = useContext(GROUPS);
  const fetchGroups = useFetchGroups(user);

  const [make_req, status] = useRequest(fetchGroups);

  const [total_groups, set_total_groups] = useStateIfMounted(
    list?.length ?? -1
  );

  useEffect(() => {
    if (!list || list.length === 0) {
      make_req(0, SIDEBAR_GROUP_LIST_LIMIT + 1).then(({ data, meta }) => {
        dispatch.reset(data);
        set_total_groups((meta?.total_result_count as number) ?? -1);
      });
    }
  }, []);

  const show_more = total_groups > SIDEBAR_GROUP_LIST_LIMIT;
  const groups = useMemo(
    () => (list && show_more ? list.slice(0, SIDEBAR_GROUP_LIST_LIMIT) : list),
    [list, show_more]
  );

  const content = useAsyncRenderResult(
    (results: Group[]) => (
      <div className="group-list">
        {results.map(group => (
          <SidebarGroupListItem key={group.id} group={group} />
        ))}
        {show_more && (
          <a className="plain-link group-list__more" href="/groups">
            View All
          </a>
        )}
      </div>
    ),
    [show_more],
    status,
    groups,
    true,
    undefined,
    SidebarGroupListLoading
  );

  return (
    <PageSidebar className="home-sidebar">
      {!status.pending && status.success && groups?.length === 0 ? null : (
        <SidebarSection
          moreLink={
            <PermCheck type="age">
              <LinkWithIcon
                className="filled gray pill"
                icon="add"
                side="right"
                collapse={true}
                href="/groups/create"
              >
                Create Group
              </LinkWithIcon>
            </PermCheck>
          }
          name="Your Groups"
        >
          {content}
        </SidebarSection>
      )}
      <SidebarCoaches />
    </PageSidebar>
  );
};

const SidebarGroupListItem: Preact.FunctionComponent<{ group: Group }> = ({
  group,
}) => {
  const { name, background_color } = group;
  const rgb = useMemo(() => hex_to_rgb(background_color), [background_color]);
  const backgroundColor =
    rgb === null
      ? DEFAULT_GROUP_BACKGROUND_COLOR
      : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;

  const link_listener = useCallbackRef<EventListener>(
    (e: EventFor<HTMLElement>) => {
      // ignore if clicking on a button, link, or any element with a click listener, don't navigate
      if (
        e.target.tagName === "A" ||
        e.target.tagName === "BUTTON" ||
        e.target.classList.contains("expandable-image") ||
        // @ts-expect-error:
        e.ignoreLinkClick
      ) {
        return;
      }
      cache_record(group, true);
      route(get_url_for_entity(group));
    },
    [group]
  );

  const listeners = useSingleClickDetection(link_listener);

  const background_image_url = useMedia<"Group", "background_image">(
    group,
    "background_image",
    undefined,
    undefined,
    "small"
  );

  return (
    <div
      className="group-list__item color-overlay"
      style={{
        backgroundImage: `url(${background_image_url})`,
        backgroundColor,
        "--color-overlay-background-color": backgroundColor,
      }}
      tabIndex={0}
      {...listeners}
    >
      <div
        className="group-list__item__color-overlay"
        data-has-image={`${!!background_image_url}`}
      >
        <div className="group-list__item__top">
          <div className="group-list__item__community-label" />
          <div className="group-list__item__title">{display_text(name)}</div>
        </div>
      </div>
    </div>
  );
};

const SidebarGroupListLoading: Preact.FunctionComponent = () => (
  <div className="group-list loading-item__list">
    {[0, 0, 0, 0].map((_, i) => (
      <div
        key={i}
        className="group-list__item group-list__item__loading loading-item"
      >
        <div className="loading-item__title loading-item__shaded" />
        <div className="loading-item__row">
          <AvatarListLoading
            size="xs"
            maxWidth={4}
            maxHeight={1}
            align="left"
          />
        </div>
      </div>
    ))}
  </div>
);

const SidebarCoaches: Preact.FunctionComponent = () => {
  const self = useAppUser();

  const get_coaches_req = useApiMethod("getConnections");
  const get_coaches_mapped = useCallback(
    () =>
      get_coaches_req({
        query: {
          filter: connection_coach_filter(self),
          include: ["users.User:profile_picture"],
          sort: [{ by: "raw_health", dir: "asc" }],
          include_count: true,
          limit: SIDEBAR_CONN_LIST_LIMIT,
        },
      }).then(({ data, ...result }) => ({
        ...result,
        data: data
          .map(c => {
            const other_user = c.users?.find(u => u.id !== self?.id);
            if (!other_user) {
              return null;
            }
            return { ...c, other_user };
          })
          .filter(c => !!c) as ConnectionMapped[],
      })),
    [get_coaches_req, self?.id]
  );

  const [result, send_req, status] = useControlledRequest(get_coaches_mapped);

  useEffect(() => {
    send_req();
  }, []);

  const content = useAsyncRenderResult(
    (results: ConnectionMapped[]) => (
      <div className="user-list">
        {results.map(conn => {
          const { has_coach, health_tier, other_user } = conn;
          const coach = has_coach
            ? has_coach.id === other_user.id
              ? "coach"
              : "coachee"
            : "";
          return (
            <UserListItem
              key={`home-sidebar-coaches-${conn.id}`}
              user={other_user}
              size="md"
              linkBox="all"
              health={health_tier}
              cardLabel={capitalize(coach)}
            />
          );
        })}
      </div>
    ),
    [],
    status,
    result?.data,
    true,
    undefined,
    SidebarCoachesLoading
  );
  const show_more =
    (result?.meta?.total_result_count || 0) > SIDEBAR_CONN_LIST_LIMIT;
  return status.pending || status.error || result?.data.length ? (
    <SidebarSection
      className="home-sidebar__coaches"
      name="Coaching Relationships"
    >
      {content}
      {show_more && (
        <a className="plain-link group-list__more" href="/people">
          View All
        </a>
      )}
    </SidebarSection>
  ) : null;
};

const SidebarCoachesLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <div className="user-list loading-item__list">
      {items.map((_, i) => (
        <UserListItemLoading key={i} />
      ))}
    </div>
  );
};
