import * as Preact from "preact";
import { maybeClassName } from "@thrive-web/ui-utils";

export const EmptyList: Preact.FunctionComponent<
  MaybeClass & { button?: Preact.VNode }
> = ({ className, button, children }) => (
  <div className={`empty-list${maybeClassName(className)}`}>
    <div className="empty-list__content">
      {button ? (
        <div className="empty-list__layout">
          {children}
          <div className="empty-list__layout__button">{button}</div>
        </div>
      ) : (
        children
      )}
    </div>
  </div>
);
