import * as Preact from "preact";
import { useMemo, useRef } from "preact/hooks";
import { Notification } from "@thrive-web/ui-api";
import {
  NotificationListItem,
  NotificationPageLoading,
} from "~/view/components";
import {
  EmptyList,
  LazyListSection,
  PageBody,
  PageContent,
  PageHeader,
  useLazyList,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { useDocumentTitle } from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import { useNotificationList } from "./utils";

export const NotificationPageList: Preact.FunctionComponent<{
  results: Notification[];
  loadMoreElem: Preact.VNode | null;
  show_new?: () => void;
  on_update: (id: string, notif: Notification | null) => void;
  read_notif: (notif: Notification) => void;
}> = ({ results, loadMoreElem, ...props }) => {
  const content = useLazyList(
    results,
    n => (
      <NotificationListItem
        className="card"
        key={n.id}
        item={n}
        onClick={props.read_notif}
        onUpdate={props.on_update}
        visible={true}
        isMenu={false}
      />
    ),
    [props.read_notif, props.on_update],
    30
  );

  if (results.length === 0) {
    return (
      <EmptyList>
        <p>You currently have no notifications.</p>
      </EmptyList>
    );
  }

  return (
    <ul className="notif-page__list">
      {props.show_new && (
        <li className="notif-page__list__show-new">
          <button className="plain-link" onClick={props.show_new}>
            Show new notifications
          </button>
        </li>
      )}
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && (
        <li className="notif-page__list__load-more">{loadMoreElem}</li>
      )}
    </ul>
  );
};

export const NotificationPage: Preact.FunctionComponent<RoutePageProps> =
  () => {
    useDocumentTitle(() => make_title(["Notifications"]), []);
    const [
      list,
      dispatch,
      get_notifs,
      status,
      read_notif,
      on_update,
      ,
      show_new,
    ] = useNotificationList();

    const first_success = useRef(false);
    if (!first_success.current && status.success) {
      first_success.current = true;
    }

    const passthrough = useMemo(
      () => ({
        read_notif,
        on_update,
        show_new,
      }),
      [read_notif, on_update, show_new]
    );

    const content = useRenderDynamicListWithPagedFetch(
      first_success.current ? list : null,
      dispatch,
      (results: Notification[], load_more_elem, pending, passthrough) => (
        <NotificationPageList
          results={results}
          loadMoreElem={load_more_elem}
          {...passthrough!}
        />
      ),
      [],
      get_notifs,
      passthrough,
      {
        PendingView: NotificationPageLoading,
        limit: 15,
      }
    );
    return (
      <PageContent
        id="activity"
        data-page="notification-list"
        className="list-page notification-page no-separate-header"
      >
        <PageHeader title={<h1>Notifications</h1>} />
        <PageBody>{content}</PageBody>
      </PageContent>
    );
  };
