import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { ConnectionMapped, User } from "@thrive-web/ui-api";
import { analytics } from "@thrive-web/ui-common";
import {
  useApiMethod,
  useDocumentTitle,
  useDynamicListVariable,
  useModal,
  useRenderPropsFunction,
  useStateIfMounted,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { createNamedContext, make_title } from "@thrive-web/ui-utils";
import {
  ButtonWithIcon,
  Icon,
  ConnectionListItem,
  EmptyList,
  useSiteHeaderContent,
  SearchBar,
  useRenderDynamicListWithPagedFetch,
  UserListItemLoading,
  ViewMoreLoader,
  connection_coach_filter,
  useLazyList,
  LazyListSection,
  DefaultModalContent,
  DeleteModalBody,
  usePermCheck,
  useRenderDynamicListWithPagedFetchRef,
  useConnectionList,
  IS_ADMIN_UI,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import { asSubroute } from "../../asSubroute";

export const CONNECTION_LIST_USER: Preact.Context<User | null> =
  createNamedContext(null, "ConnectionListUser");

export const ConnectionListView: Preact.FunctionComponent<{
  title?: Preact.ComponentChildren;
  connections: readonly ConnectionMapped[];
  openModal: () => void;
  searchString?: string;
  loadMoreElem?: Preact.VNode | null;
  hideOnEmpty?: boolean;
  onChangeCoach: (conn: ConnectionMapped) => void;
  onClickRemove: (conn: ConnectionMapped, after_remove: () => void) => void;
}> = ({
  title,
  connections,
  openModal,
  searchString,
  loadMoreElem,
  hideOnEmpty,
  onChangeCoach,
  onClickRemove,
}) => {
  const user = useContext(CONNECTION_LIST_USER);
  const user_lite = useMemo(
    () => ({
      ...user!,
      has_connection: undefined,
    }),
    [user?.id]
  );

  const content = useLazyList(
    connections,
    c => (
      <ConnectionListItem
        key={c.id}
        connection={c}
        user={user_lite}
        onChangeCoach={onChangeCoach}
        onClickRemove={onClickRemove}
      />
    ),
    [user?.id],
    20
  );

  if (!user || !connections || (connections.length === 0 && hideOnEmpty)) {
    return null;
  }

  const page_content = connections.length ? (
    <Preact.Fragment>
      <ul className="people-connections__list lazy-list">
        {content.map((s, i) => (
          <LazyListSection key={i}>{s}</LazyListSection>
        ))}
      </ul>
      {loadMoreElem && (
        <div className="user-list__load-more">{loadMoreElem}</div>
      )}
    </Preact.Fragment>
  ) : (
    <EmptyList
      className="people-connections__list--empty"
      button={
        searchString ? undefined : (
          <ButtonWithIcon
            className="filled gray"
            icon="add"
            side="left"
            onClick={openModal}
          >
            New Connection
          </ButtonWithIcon>
        )
      }
    >
      {searchString ? (
        <div className="empty-list__layout empty-list--search">
          <div className="empty-list__layout__top">
            <Icon iconSize="xl" name="find-contacts" />
            <h3>
              {searchString
                ? `No matches found for "${searchString}"`
                : "Looking to make a connection?"}
            </h3>
          </div>
        </div>
      ) : (
        <div className="empty-list__layout">
          <p>
            {searchString
              ? `No connections matching "${searchString}".`
              : "You have no connections. Try searching for friends and get started!"}
          </p>
        </div>
      )}
    </EmptyList>
  );

  return (
    <div className="page-tab__section">
      <div className="page-tab__section__title">{title}</div>
      <div className="page-tab__record-list">{page_content}</div>
    </div>
  );
};

const ConnectionListSearch: Preact.FunctionComponent<{
  openModal: () => void;
  search?: string;
  onChangeCoach: (conn: ConnectionMapped) => void;
  onClickRemove: (conn: ConnectionMapped, after_remove: () => void) => void;
}> = ({ openModal, search, onChangeCoach, onClickRemove }) => {
  const user = useContext(CONNECTION_LIST_USER);
  const [list, dispatch] = useDynamicListVariable<ConnectionMapped>(null);

  // use list of connections for this user, with search
  const getConnectionsMapped = useConnectionList(user, search);

  const passthrough = useMemo(
    () => ({ searchString: search, onChangeCoach, onClickRemove }),
    [search, onChangeCoach, onClickRemove]
  );

  return useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem, pending, passthroughProps) => (
      <ConnectionListView
        connections={result}
        openModal={openModal}
        loadMoreElem={load_more_elem}
        {...passthroughProps!}
      />
    ),
    [openModal],
    getConnectionsMapped,
    passthrough,
    { PendingView: ConnectionListLoading, limit: 20 }
  );
};
export const ConnectionListPage: Preact.FunctionComponent<{
  openModal: () => void;
  user: User | null;
  onUpdate: (
    conns: readonly ConnectionMapped[] | null,
    updated?: ConnectionMapped
  ) => void;
}> = ({ openModal, user, onUpdate }) => {
  const [coach_list, coach_dispatch] =
    useDynamicListVariable<ConnectionMapped>(null);
  const [other_list, other_dispatch] =
    useDynamicListVariable<ConnectionMapped>(null);
  const [search, set_search, search_ref] = useStateRef("");

  // is the current user an adult
  const is_adult = usePermCheck("age", {});

  // when a list is updated (e.g. set coach[ee]), call the update callback using the
  // connection that was updated
  const updated_conn = useRef<ConnectionMapped | undefined>(undefined);
  useEffect(() => {
    onUpdate(
      // todo: enable this when filter is added
      // coach_list ? coach_list.concat(other_list || []) : other_list
      other_list,
      updated_conn.current
    );
    updated_conn.current = undefined;
  }, [onUpdate, coach_list, other_list]);

  const getOtherConnections = useConnectionList(user, search);

  const get_coaches_req = useApiMethod("getConnections");
  const get_coaches_mapped = useCallback(
    (offset: number, limit?: number) =>
      get_coaches_req({
        query: {
          filter: connection_coach_filter(user),
          include: ["users.User:profile_picture"],
          sort: [{ by: "created_at", dir: "desc" }],
          include_count: true,
          fields: {
            User: [
              ...DEFAULT_USER_FIELDS,
              "phone_number",
              "is_adult",
              "has_current_mood",
            ],
          },
          offset,
          limit,
        },
      }).then(({ data, ...result }) => ({
        ...result,
        data: data
          .map(c => {
            const other_user = c.users?.find(u => u.id !== user?.id);
            if (!other_user) {
              return null;
            }
            return { ...c, other_user };
          })
          .filter(c => !!c) as ConnectionMapped[],
      })),
    [get_coaches_req, user]
  );

  const no_coaches = !!coach_list && coach_list.length === 0;

  const [delete_target, set_delete_target] = useStateIfMounted<{
    connection: ConnectionMapped | null;
    afterDelete: (() => void) | null;
  }>({ connection: null, afterDelete: null });

  const deleteConnection = useApiMethod("deleteConnection");
  const doDelete = useCallback(() => {
    if (!delete_target.connection) {
      return Promise.reject("No connection specified");
    }
    return deleteConnection(delete_target.connection.id).then(result => {
      analytics.log_event(analytics.EVENTS.remove_connection);
      return result;
    });
  }, [deleteConnection, delete_target]);
  const DeleteConnectionModalBody = useRenderPropsFunction<
    ModalBodyProps & {
      deleteRecord: () => Promise<void>;
      afterDelete: () => void;
      connection: ConnectionMapped;
    }
  >(
    ({ closeButton, connection, ...props }) => (
      <DefaultModalContent title="Delete Connection" closeButton={closeButton}>
        <DeleteModalBody {...props} hideWarning={true}>
          Are you sure you want to remove {connection.other_user.full_name} as a
          connection?
        </DeleteModalBody>
      </DefaultModalContent>
    ),
    "GroupConnectionDeleteModal-Bound",
    []
  );
  const delete_body_props = useMemo(
    () => ({
      deleteRecord: doDelete,
      ...delete_target,
    }),
    [delete_target, doDelete]
  );

  const [deleteConnectionsModal, openDeleteConnectionsModal] = useModal({
    id: "delete-connections-modal",
    innerClassName: "card card-stack modal-form group-connections__modal",
    body: DeleteConnectionModalBody,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
    bodyProps: delete_body_props,
  });

  const passthrough = useMemo(
    () => ({
      onClickRemove: (
        connection: ConnectionMapped,
        after_remove?: () => void
      ) => {
        set_delete_target({ connection, afterDelete: after_remove || null });
        openDeleteConnectionsModal(true);
      },
      onChangeCoach: (conn: ConnectionMapped) => {
        updated_conn.current = conn;
        // put the conn in the appropriate list
        if (conn.has_coach) {
          coach_dispatch.update(c => c.id === conn.id, conn, "end");
          other_dispatch.remove(c => c.id === conn.id, false);
        } else {
          other_dispatch.update(c => c.id === conn.id, conn, "start");
          coach_dispatch.remove(c => c.id === conn.id, false);
        }
      },
    }),
    [coach_dispatch, other_dispatch, openDeleteConnectionsModal]
  );

  const [coach_content, coach_render_ref] =
    useRenderDynamicListWithPagedFetchRef(
      coach_list,
      coach_dispatch,
      (result, load_more_elem, pending, passthroughProps) => (
        <ConnectionListView
          title="Coaching Relationships"
          connections={result}
          openModal={openModal}
          loadMoreElem={load_more_elem}
          hideOnEmpty={true}
          {...passthroughProps!}
        />
      ),
      [openModal],
      get_coaches_mapped,
      passthrough,
      {
        PendingView: ConnectionListLoading,
        limit: 8,
        LoadMoreComponent: ViewMoreLoader,
      }
    );

  useEffect(() => {
    coach_render_ref.current?.setStateIfMounted(
      { list: coach_list },
      function () {
        // tell the AsyncRenderPaged component to remove duplicates from the list
        // before displaying it
        this.check_duplicates = true;
      }
    );
  }, [coach_list]);

  const [content, other_render_ref] = useRenderDynamicListWithPagedFetchRef(
    other_list,
    other_dispatch,
    (result, load_more_elem, pending, passthroughProps) => (
      <ConnectionListView
        title={`${no_coaches ? "Your" : "Other"} Connections`}
        connections={result}
        openModal={openModal}
        loadMoreElem={load_more_elem}
        hideOnEmpty={!no_coaches}
        {...passthroughProps!}
      />
    ),
    [openModal, !no_coaches],
    getOtherConnections,
    passthrough,
    {
      PendingView: ConnectionListLoading,
      limit: 20,
      LoadMoreComponent: ViewMoreLoader,
    }
  );

  useEffect(() => {
    other_render_ref.current?.setStateIfMounted(
      { list: other_list },
      function () {
        // tell the AsyncRenderPaged component to remove duplicates from the list
        // before displaying it
        this.check_duplicates = true;
      }
    );
  }, [other_list]);

  // fetch on mount
  // useEffect(() => {
  //   getConnectionsMapped(0).then;
  //   // if search is active on unmount, fetch the raw list again
  // }, []);

  const onSubmit = useCallback(
    (str: string) => {
      if (!str && !search_ref.current) {
        return;
      }
      set_search(str);
    },
    [getOtherConnections]
  );
  const is_admin_ui = useContext(IS_ADMIN_UI);
  const attachSearchBar = useSiteHeaderContent(true);

  return (
    <Preact.Fragment>
      {!!search && is_adult ? (
        <ConnectionListSearch
          openModal={openModal}
          search={search}
          onChangeCoach={passthrough.onChangeCoach}
          onClickRemove={passthrough.onClickRemove}
        />
      ) : (
        <Preact.Fragment>
          {coach_content}
          {content}
        </Preact.Fragment>
      )}
      {deleteConnectionsModal}
      {is_adult &&
        !is_admin_ui &&
        attachSearchBar(<SearchBar onSubmit={onSubmit} placeholder="Search" />)}
    </Preact.Fragment>
  );
};

export const ConnectionListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <ul className="people-connections__list loading-item__list">
      {items.map((_, i) => (
        <UserListItemLoading size="lg" />
      ))}
    </ul>
  );
};

const ConnectionListBase: Preact.FunctionComponent<
  RoutePageProps & {
    openModal: () => void;
    user: User | null;
    onUpdate: (conns: readonly ConnectionMapped[] | null) => void;
  }
> = props => {
  const { Provider } = CONNECTION_LIST_USER;
  useDocumentTitle(() => make_title(["Your Connections"]), []);
  return (
    <Provider value={props.user}>
      <ConnectionListPage {...props} />
    </Provider>
  );
};
export const ConnectionList = asSubroute(ConnectionListBase);
