import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { DocBase } from "@thrive-web/core";
import { User } from "@thrive-web/ui-api";
import { group_invite_search_filter } from "@thrive-web/ui-utils";
import {
  ButtonWithIcon,
  RequestButton,
  UserInviteListItem,
  RemovableListItem,
  RemovableListItemComponentProps,
  SelectUserModal,
  GroupMemberInviteLinkButton,
  useGroupJoin,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import {
  useApiFetch,
  useApiMethod,
  useAppUser,
  useRenderPropsFunction,
  useRequest,
  useStateRef,
} from "@thrive-web/ui-hooks";

export const GroupMemberInvite: Preact.FunctionComponent<{
  onFinish: () => void;
  members: readonly User[];
  addMember: (member: User) => void;
  allowLink: boolean;
  group_id: string;
  isAdmin: boolean;
  emptyLabel?:
    | string
    | Preact.VNode
    | ((search: string) => string | Preact.VNode);
}> = ({
  onFinish,
  members,
  addMember,
  group_id,
  allowLink,
  isAdmin,
  emptyLabel = "There are no users available to invite.",
}) => {
  const user = useAppUser();
  const searchRequest = useApiMethod("getUsers");
  const sendSearchRequest = useCallback(
    (
      search: string,
      offset: number,
      limit?: number
    ): Promise<DocBase & { data: User[] }> =>
      searchRequest({
        query: {
          filter: group_invite_search_filter(user?.id, group_id, search),
          sort: [{ by: "full_name", dir: "asc" }],
          limit,
          offset,
          include_count: true,
          include: ["profile_picture"],
          fields: {
            User: DEFAULT_USER_FIELDS,
          },
        },
      }),
    [searchRequest]
  );

  const [, set_pending_count, pending_ref] = useStateRef(0);
  const inc_pending = useCallback(() => {
    set_pending_count(pending_ref.current + 1);
  }, []);
  const on_success = useCallback(
    (member: User) => {
      addMember(member);
      set_pending_count(pending_ref.current - 1);
    },
    [addMember]
  );
  const renderUser = useRenderPropsFunction(
    (u: User, _, remove_on_select?: (user: User) => void) => (
      <GroupMemberInviteListItem
        key={u.id}
        user={u}
        onSendRequest={inc_pending}
        onInvite={on_success}
        removeOnInvite={remove_on_select}
        group_id={group_id}
        invited={!!members.find(m => m.id === u.id)}
        isAdmin={isAdmin}
      />
    ),
    "GroupMemberInviteListItem-User",
    [on_success, group_id, members]
  );

  return (
    <Preact.Fragment>
      <SelectUserModal
        selected={members}
        getUsers={sendSearchRequest}
        renderUser={renderUser}
        emptyLabel={emptyLabel}
      >
        {allowLink ? (
          <div className="group-members__modal__share">
            <GroupMemberInviteLinkButton groupId={group_id} />
          </div>
        ) : undefined}
      </SelectUserModal>
      <div className="modal__footer">
        <div />
        <ButtonWithIcon
          className="filled gray"
          icon="checked"
          side="right"
          type="submit"
          onClick={onFinish}
        >
          Save & Finish
        </ButtonWithIcon>
      </div>
    </Preact.Fragment>
  );
};

type GroupMemberInviteProps = {
  user: User;
  onInvite: (user: User) => void;
  removeOnInvite?: (user: User) => void;
  group_id: string;
  invited: boolean;
  onSendRequest?: () => void;
  disabled?: boolean;
  isAdmin?: boolean;
};
export const GroupMemberInviteListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<GroupMemberInviteProps>
> = ({
  user,
  group_id,
  invited,
  onRemoveItem,
  onSendRequest,
  disabled,
  isAdmin,
}) => {
  const req_params = useMemo(
    () => ({
      body: { data: [{ id: user.id }] },
    }),
    [user?.id]
  );
  // direct add (if cur user is admin)
  const add_member_direct = useApiFetch("addGroupMember", group_id, req_params);
  // join via GroupJoin record
  const add_member_join = useGroupJoin({ id: group_id }, user);
  const add_member = useCallback(() => {
    if (isAdmin) {
      return add_member_direct();
    }
    return add_member_join();
  }, [add_member_direct, add_member_join, isAdmin]);

  const [send_request, { pending, success, error }] = useRequest(add_member);
  const addMember = useCallback(() => {
    // cancel if already added
    if (invited) {
      return;
    }
    onSendRequest?.();
    send_request()
      .then(res => {
        analytics.log_event(analytics.EVENTS.group_add_user);
        return res;
      })
      .then(onRemoveItem);
  }, [send_request, user, onRemoveItem, invited, onSendRequest]);

  return (
    <UserInviteListItem
      user={user}
      button={
        <RequestButton
          className={`filled ${success ? "success" : "gray"}`}
          pending={pending}
          success={success || invited}
          successText="Invited"
          error={error}
          showError={true}
          onClick={disabled ? undefined : addMember}
          disabled={disabled}
        >
          Invite
        </RequestButton>
      }
    />
  );
};

export const GroupMemberInviteListItem: Preact.FunctionComponent<GroupMemberInviteProps> =
  props => {
    const onRemoveItem = useCallback(() => {
      props.removeOnInvite?.(props.user);
      props.onInvite(props.user);
      return Promise.resolve();
    }, [props.onInvite, props.user, props.removeOnInvite]);
    return (
      <RemovableListItem<
        RemovableListItemComponentProps<GroupMemberInviteProps>,
        User
      >
        {...props}
        delay={1000}
        Component={GroupMemberInviteListItemBase}
        onRemoveItem={onRemoveItem}
      />
    );
  };
