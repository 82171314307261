import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { Community, Post } from "@thrive-web/ui-api";
import {
  ActivityFeed,
  ACTIVITY_FEED_POST_ID,
  asSubroute,
  PostCreatePrompt,
  useRenderDynamicListWithPagedFetch,
  PostListLoading,
  COMMUNITY_POSTS,
  DEFAULT_USER_FIELDS,
  EmptyList,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useAppUser,
  useDocumentTitle,
} from "@thrive-web/ui-hooks";
import { get_url_for_entity, make_title } from "@thrive-web/ui-utils";
import { ApiMethodParameters } from "@thrive-web/core";

const POST_DETAIL_PATH = "/community/:id/activity/:post";
const PostIdProvider = ACTIVITY_FEED_POST_ID.Provider;

const yes = () => true;
const CommunityActivity: Preact.FunctionComponent<
  RoutePageProps & {
    community: Community;
    post_id?: string;
  }
> = ({ community, post_id }) => {
  useDocumentTitle(
    () => (community.name ? make_title([community.name, "Activity"]) : null),
    [community.name]
  );
  const self = useAppUser();
  const req_params = useMemo<ApiMethodParameters<"GET", "Post">>(
    () => ({
      query: {
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
        include: [
          "has_touchpoint",
          "has_reaction",
          "has_expense",
          "mood",
          "photo",
          "event.Event:cover_image",
          "created_by.User:profile_picture",
          "has_tagged_user.User:profile_picture",
          "has_comment.Comment:created_by.User:profile_picture",
        ],
        filter: [
          ["=", ["this", "Post:posted_to"], ["id", community!.id as string]],
        ],
        sort: [{ by: "created_at", dir: "desc" }],
      },
    }),
    [community?.id]
  );
  const getPostsReq = useApiFetchPaged("getPosts", req_params);
  const getPosts = useCallback(
    (offset: number, limit?: number) =>
      getPostsReq(offset, limit).then(res => ({
        ...res,
        data: res.data.map(p => ({ ...p, posted_to: community })),
      })),
    []
  );

  const detailExitPath = useMemo(
    () => `${get_url_for_entity(community)}/activity`,
    [community]
  );

  const { list, dispatch } = useContext(COMMUNITY_POSTS);
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more) => (
      <ActivityFeed
        posts={result}
        detailPath={POST_DETAIL_PATH}
        detailExitPath={detailExitPath}
        dynamicListCtx={COMMUNITY_POSTS}
        loadMoreElem={load_more}
        emptyView={
          <EmptyList>
            <p>This community currently has no activity.</p>
          </EmptyList>
        }
        canInteract={yes}
      />
    ),
    [community],
    getPosts,
    undefined,
    { PendingView: PostListLoading }
  );
  const onAddPost = useCallback(
    (new_post: Post) => {
      dispatch.add(new_post, "start");
    },
    [dispatch]
  );

  const is_moderator = useMemo(() => {
    return (
      !!self &&
      !!community &&
      [
        community.has_manager,
        community.has_moderator,
        community.has_admin,
      ].some(users => users?.some(u => u.id === self.id))
    );
  }, [
    self?.id,
    community.has_manager,
    community.has_moderator,
    community.has_admin,
  ]);

  if (!community) {
    return null;
  }
  return (
    <div className="community-activity__content">
      {is_moderator && (
        <div className="community-activity__create-post">
          <PostCreatePrompt defaultScope={community} onFinish={onAddPost} />
        </div>
      )}
      <PostIdProvider value={post_id}>{content}</PostIdProvider>
    </div>
  );
};

export const CommunityActivityPage = asSubroute(CommunityActivity);
