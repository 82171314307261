import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { Group, Todo, WriteTodo } from "@thrive-web/ui-api";
import {
  asSubroute,
  ButtonWithIcon,
  useRenderDynamicListWithPagedFetch,
  GROUP_DETAIL_CONTEXTS,
  GROUP_DETAIL_LIST_CONTEXTS,
  DEFAULT_USER_FIELDS,
  EmptyList,
} from "@thrive-web/ui-components";
import { insert_todo_sorted, todo_query } from "./utils";
import {
  useApiFetchPaged,
  useApiMethod,
  useCrudModal,
  useDocumentTitle,
  useDynamicListVariable,
} from "@thrive-web/ui-hooks";
import { make_title } from "@thrive-web/ui-utils";
import {
  GroupTodoListItem,
  GroupTodoListItemLoading,
} from "./GroupTodoListItem";

import {
  GroupTodoCreateModal,
  GroupTodoDeleteModal,
  GroupTodoEditModal,
} from "./GroupTodoCrudModals";

const GroupTodosBase: Preact.FunctionComponent<
  RoutePageProps & { group: Group }
> = ({ group }) => {
  const { list, dispatch: updateTodos } = useContext(
    GROUP_DETAIL_LIST_CONTEXTS.todos
  );
  useDocumentTitle(
    () => make_title(group?.name ? ["To-Dos", group.name] : ["Groups"]),
    [group?.name]
  );

  const todos_params = useMemo(
    () => ({
      query: todo_query(group),
    }),
    [group?.id]
  );
  const getTodos = useApiFetchPaged("getTodos", todos_params);

  // list of todos that were created after the page was loaded
  const [added_items, update_added] = useDynamicListVariable<Todo>([]);
  useEffect(() => {
    added_items?.forEach(i => {
      if (list?.find(li => li.id === i.id)) {
        update_added.remove(li => li.id === i.id);
      }
    });
  }, [list]);
  const passthrough_props = useMemo(() => {
    if (!list && added_items?.length === 0) {
      return { todos: null };
    }
    if (list?.length === 0 || added_items?.length === 0) {
      return { todos: list };
    }

    return {
      todos:
        // insert new todos into the list in sorted order
        added_items?.reduce(
          (output, item) => insert_todo_sorted(item, output, updateTodos.reset),
          list
        ) || list,
    };
  }, [list, added_items]);

  const content = useRenderDynamicListWithPagedFetch(
    list,
    updateTodos,
    (result, load_more_elem, _, passthrough_props) => (
      <GroupTodosListView
        todos={passthrough_props?.todos || result}
        loadMoreElem={load_more_elem}
      />
    ),
    [],
    getTodos,
    passthrough_props,
    {
      PendingView: GroupTodosLoading,
      limit: 10,
    }
  );

  const createTodo = useApiMethod("createTodo");
  const createBodyProps = useMemo(
    () => ({
      onSubmit: (new_todo: WriteTodo) =>
        createTodo({
          body: {
            data: map_record_to_json_resource(new_todo as Todo, TYPES["Todo"]),
          },
        }).then(result => {
          update_added.add(result.data);
          return result;
        }),
    }),
    [createTodo, updateTodos]
  );

  const [createTodosModal, openCreateTodosModal] = useCrudModal(
    "create-todos-modal",
    GroupTodoCreateModal,
    createBodyProps,
    "group-todos__modal"
  );

  return (
    <div className="page-tab page-tab group-todos-page">
      <div className="page-tab__top">
        <ButtonWithIcon
          icon="add"
          side="left"
          className="filled gray"
          onClick={() => {
            analytics.log_event(analytics.EVENTS.to_do.to_do);
            openCreateTodosModal({ id: "" });
          }}
        >
          Add a To-Do
        </ButtonWithIcon>
      </div>
      {content}
      {createTodosModal}
    </div>
  );
};

const GroupTodosListView: Preact.FunctionComponent<{
  todos: readonly Todo[];
  loadMoreElem?: Preact.VNode | null;
}> = ({ todos, loadMoreElem }) => {
  const updateTodos = useContext(GROUP_DETAIL_LIST_CONTEXTS.todos).dispatch;

  const editTodo = useApiMethod("updateTodo");
  const editBodyProps = useMemo(
    () => ({
      onSubmit: (patched: Todo) =>
        editTodo(patched.id, {
          body: {
            data: map_record_to_json_resource(patched, TYPES["Todo"]),
          },
          query: {
            include: ["goal", "assigned_to.User:profile_picture"],
            fields: { User: DEFAULT_USER_FIELDS },
          },
        }).then(result => {
          setTimeout(
            () =>
              updateTodos.update(item => item.id === patched.id, result.data),
            800
          );
          return result;
        }),
    }),
    [editTodo, updateTodos]
  );

  const [editTodosModal, set_edit_target] = useCrudModal(
    "edit-todos-modal",
    GroupTodoEditModal,
    editBodyProps,
    "group-todos__modal"
  );

  const deleteTodo = useApiMethod("deleteTodo");
  const deleteBodyProps = useMemo(
    () => ({
      onSubmit: (patched: Todo) =>
        deleteTodo(patched.id).then(result => {
          analytics.log_event(analytics.EVENTS.to_do_deleted);
          setTimeout(
            () => updateTodos.remove(item => item.id === patched.id),
            800
          );
          return result;
        }),
    }),
    [deleteTodo, updateTodos]
  );

  const [deleteTodosModal, set_delete_target] = useCrudModal(
    "delete-todos-modal",
    GroupTodoDeleteModal,
    deleteBodyProps,
    "group-todos__modal"
  );

  if (!todos) {
    return <GroupTodosLoading />;
  }

  todos = [];
  if (todos?.length === 0) {
    return (
      <EmptyList>
        <p>There are no todos for this group yet.</p>
      </EmptyList>
    );
  }

  return (
    <Preact.Fragment>
      <div className="page-tab__section">
        <div className="page-tab__section__title">Group To-Dos</div>
        <div className="page-tab__record-list group-todos__list">
          <ul>
            {todos.map(todo => (
              <GroupTodoListItem
                key={todo.id}
                todo={todo}
                editTodo={set_edit_target}
                deleteTodo={set_delete_target}
                updateRecord={editBodyProps.onSubmit}
              />
            ))}
            {loadMoreElem && (
              <li className="todo-list__load-more">{loadMoreElem}</li>
            )}
          </ul>
        </div>
      </div>
      {editTodosModal}
      {deleteTodosModal}
    </Preact.Fragment>
  );
};

export const GroupTodos: Preact.FunctionComponent<RoutePageProps> = props => {
  const group = useContext(GROUP_DETAIL_CONTEXTS.group);
  return !group ? (
    <GroupTodosLoading />
  ) : (
    <GroupTodosBase {...props} group={group} />
  );
};
export const GroupTodosPage = asSubroute(GroupTodos);

export const GroupTodosLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <div className="page-tab__section">
      <div className="page-tab__section__title">Group To-Dos</div>
      <div className="page-tab__record-list group-todos__list">
        <ul className="loading-item__list">
          {items.map((_, i) => (
            <GroupTodoListItemLoading key={i} />
          ))}
        </ul>
      </div>
    </div>
  );
};
