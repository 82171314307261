import { ApiMethodParameters, DocBase } from "@thrive-web/core";
import {
  filter_user_connected,
  filter_user_is_adult,
} from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { getCurrentUrl, route } from "preact-router";
import { stringify } from "query-string";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import {
  useAppUser,
  useDynamicListVariable,
  useApiFetchPaged,
  usePreviousValue,
  useStateIfMounted,
  useApiRequestPaged,
  useStateRef,
  useValueRef,
  useModal,
} from "@thrive-web/ui-hooks";
import {
  HomeSidebar,
  HomeSearchPeople,
  HomeSearchPeopleLoading,
  GroupScoreModal,
} from "~/view/components";
import {
  PageBody,
  PageHeader,
  PostCreatePrompt,
  ActivityFeed,
  useSiteHeaderContent,
  SearchBar,
  EmptyList,
  Transition,
  DefaultPendingView,
  PageContent,
  useRenderDynamicListWithPagedFetch,
  ACTIVITY_FEED_POST_ID,
  PostListLoading,
  WithPostScopeCtx,
  THRIVE_GROUPS,
  GROUPS,
  userCanInteract,
  usePermCheck,
  LinkWithIcon,
} from "@thrive-web/ui-components";
import { Post, User } from "@thrive-web/ui-api";
import { HOME_POSTS, HOME_POSTS_SEARCH } from "./contexts";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { HomeSearchPostType, post_query, post_search_query } from "./utils";

const HOME_POST_DETAIL_PATH = "/post/:id";

const PostIdProvider = ACTIVITY_FEED_POST_ID.Provider;

export const HomeActivityBase: Preact.FunctionComponent = () => {
  const self = useAppUser();
  const params = useMemo(() => (self ? post_query(self) : {}), [self?.id]);
  const getPosts = useApiFetchPaged("getPosts", params);

  const can_interact = userCanInteract();

  const { list, dispatch } = useContext(HOME_POSTS);
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more) => (
      <ActivityFeed
        posts={result}
        showScope={true}
        detailPath={HOME_POST_DETAIL_PATH}
        detailExitPath="/"
        dynamicListCtx={HOME_POSTS}
        loadMoreElem={load_more}
        canInteract={can_interact}
        emptyView={
          <EmptyList
            button={
              <LinkWithIcon
                className="button filled gray"
                icon="add"
                side="right"
                href="/groups/create"
              >
                Create a Group
              </LinkWithIcon>
            }
          >
            <p>
              It looks like there's no activity in your feed. Create a post or
              join a group to get started.
            </p>
          </EmptyList>
        }
      />
    ),
    [],
    getPosts,
    undefined,
    { PendingView: PostListLoading }
  );

  return content;
};

export const HomeActivitySearch: Preact.FunctionComponent<{
  search: string;
  type?: string;
  postType?: HomeSearchPostType;
  setType: (str: string) => void;
  setPostType: (type?: HomeSearchPostType) => void;
  fetchPosts: (
    limit: number,
    offset?: number
  ) => Promise<DocBase & { data: Post[] }>;
}> = ({ fetchPosts, search, type, postType, setType, setPostType }) => {
  const self = useAppUser();
  const apply_type_filter = useCallback(
    (new_type: string) => e => {
      e.preventDefault();
      setType(new_type);
    },
    [setType]
  );

  const people_params = useMemo<ApiMethodParameters<"GET", "User", false>>(
    () =>
      self
        ? {
            query: {
              filter: [
                [
                  // home search should only show users that are adults or are connected
                  "or",
                  filter_user_is_adult(),
                  filter_user_connected(self, true),
                ],
                ["match", ["this", "User:full_name"], search, "i"],
              ],
              sort: [{ by: "full_name", dir: "asc" }],
              include: ["profile_picture"],
            },
          }
        : {},
    [search, self?.id]
  );
  const prev_params = usePreviousValue(people_params);

  const [people, set_people] = useDynamicListVariable<User>(null);
  // const on_update_user = u

  const searchPeople = useApiFetchPaged(
    "getUsers",
    // @ts-expect-error:
    !search ? prev_params.current : people_params
  );

  const onUpdateUser = useCallback(
    (user: User) => {
      set_people.update(u => u.id === user.id, user);
    },
    [set_people]
  );

  const peopleList = useRenderDynamicListWithPagedFetch(
    people,
    set_people,
    (result: readonly User[], load_more, pending) => (
      <HomeSearchPeople
        users={result}
        // show all results if the search is limited to people
        fullList={type === "people"}
        setType={apply_type_filter}
        onUpdateUser={onUpdateUser}
        emptyView={
          pending ? (
            <HomeSearchPeopleLoading />
          ) : (
            <EmptyList>No results for "{search}"</EmptyList>
          )
        }
        loadMoreElem={load_more}
      />
    ),
    [type === "people", apply_type_filter, onUpdateUser, search],
    searchPeople,
    undefined,
    { PendingView: HomeSearchPeopleLoading }
  );
  const can_interact = userCanInteract();

  const { dispatch, list } = useContext(HOME_POSTS_SEARCH);
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more, pending?: boolean) => (
      <ActivityFeed
        posts={result}
        showScope={true}
        detailPath={HOME_POST_DETAIL_PATH}
        detailExitPath="/"
        dynamicListCtx={HOME_POSTS_SEARCH}
        emptyView={
          pending && result.length === 0 ? (
            <DefaultPendingView />
          ) : (
            <EmptyList>No results for "{search}"</EmptyList>
          )
        }
        loadMoreElem={
          type !== "posts" && result.length > 0 && load_more ? (
            <button onClick={apply_type_filter("posts")} className="plain-link">
              View More
            </button>
          ) : (
            load_more
          )
        }
        canInteract={can_interact}
      />
    ),
    [search],
    fetchPosts,
    undefined,
    { PendingView: PostListLoading }
  );

  return (
    <div className="home-page__search">
      <div className="home-page__search__filters">
        <button
          onClick={apply_type_filter(type === "posts" ? "" : "posts")}
          className={`pill filled${type === "posts" ? " success" : " gray"}`}
        >
          Posts
        </button>
        <button
          onClick={apply_type_filter(type === "people" ? "" : "people")}
          className={`pill filled${type === "people" ? " success" : " gray"}`}
        >
          People
        </button>
      </div>
      {type === "posts" && (
        <div className="home-page__search__filters home-page__search__filters__post">
          <button
            onClick={() =>
              setPostType(
                postType === "has_touchpoint" ? undefined : "has_touchpoint"
              )
            }
            className={`pill filled${
              postType === "has_touchpoint" ? " success" : " gray"
            }`}
          >
            Updates
          </button>
          <button
            onClick={() =>
              setPostType(postType === "event" ? undefined : "event")
            }
            className={`pill filled${
              postType === "event" ? " success" : " gray"
            }`}
          >
            Events
          </button>
          <button
            onClick={() =>
              setPostType(
                postType === "is_asking_for_help"
                  ? undefined
                  : "is_asking_for_help"
              )
            }
            className={`pill filled${
              postType === "is_asking_for_help" ? " success" : " gray"
            }`}
          >
            Help Request
          </button>
        </div>
      )}
      {type !== "posts" && peopleList}
      {type !== "people" ? content : null}
    </div>
  );
};
export const HomeActivity: Preact.FunctionComponent<{
  post_id?: string;
  query: ObjectOf<string> | undefined;
}> = ({ post_id, query }) => {
  const self = useAppUser();
  const attachSearchBar = useSiteHeaderContent(true);
  const query_ref = useValueRef(query);

  const is_adult = usePermCheck("age", {});

  const [search, set_search_value, search_ref] = useStateRef(
    query?.filter as string
  );

  const [search_type, set_search_type, search_type_ref] = useStateRef(
    query?.type as string
  );

  const [post_type, set_post_type_, post_type_ref] = useStateRef(
    query?.post_type as HomeSearchPostType | undefined
  );

  // get list of user's thread groups so we know if they can create touchpoints/expenses
  const { list: thrive_groups, fetch: fetch_thrive_groups } =
    useContext(THRIVE_GROUPS);
  const allow_group_updates = thrive_groups
    ? thrive_groups.length > 0
    : undefined;

  useEffect(() => {
    fetch_thrive_groups();
  }, [fetch_thrive_groups]);

  // update route upon changing search string
  const set_search = useCallback(
    (str: string) => {
      if (str !== search_ref.current) {
        set_search_value(str);
      }
      if (query_ref.current?.filter !== str) {
        if (str) {
          const query_obj: any = { filter: str };
          if (post_type_ref.current && str === "posts") {
            query_obj.post_type = post_type_ref.current;
          }
          if (search_type_ref.current) {
            query_obj.type = search_type_ref.current;
          }
          route(`?${stringify(query_obj)}`);
        } else if (getCurrentUrl() !== "/") {
          route("/");
        }
      }
    },
    [set_search_value]
  );

  // update route upon changing search type
  const set_type = useCallback(
    (str: string) => {
      if (str !== search_type_ref.current) {
        set_search_type(str);
      }
      if (str !== "posts" && query_ref.current?.post_type) {
        set_post_type_(undefined);
      }
      if (query_ref.current?.type !== str) {
        if (str || search_ref.current) {
          const query_obj: any = { filter: search_ref.current };
          if (post_type_ref.current && str === "posts") {
            query_obj.post_type = post_type_ref.current;
          }
          if (str) {
            query_obj.type = str;
          }
          route(`?${stringify(query_obj)}`, !!query_ref.current?.type && !!str);
        } else if (getCurrentUrl() !== "/") {
          route("/");
        }
      }
    },
    [set_search_type]
  );

  // update route upon changing post type filter
  const set_post_type = useCallback(
    (str?: HomeSearchPostType) => {
      if (str !== post_type_ref.current) {
        set_post_type_(str);
      }
      if (query_ref.current?.post_type !== str) {
        if (str || search_ref.current) {
          const query_obj: any = { filter: search_ref.current };
          if (search_type_ref.current) {
            query_obj.type = search_type_ref.current;
          }
          if (str) {
            query_obj.post_type = str;
          }
          route(
            `?${stringify(query_obj)}`,
            !!query_ref.current?.post_type && !!str
          );
        } else if (getCurrentUrl() !== "/") {
          route("/");
        }
      }
    },
    [set_post_type_]
  );

  // update filters based on url query string
  useLayoutEffect(() => {
    if (query?.filter !== search_ref.current) {
      set_search_value((query?.filter || "") as string);
    }
    if (query?.type !== search_type_ref.current) {
      set_search_type((query?.type || "") as string);
    }
    if (query?.post_type !== post_type_ref.current) {
      set_post_type_(
        (query?.post_type || undefined) as HomeSearchPostType | undefined
      );
    }
  }, [
    query?.filter,
    query?.type,
    query?.post_type,
    set_search_value,
    set_search_type,
    set_post_type_,
  ]);

  const onSearch = useCallback(
    (str: string) => {
      // clear search if user is not an adult
      if (!is_adult) {
        !!search_ref.current && set_search("");
        return;
      }
      str = str?.trim();
      if (str !== search_ref.current) {
        set_search(str);
      }
    },
    [set_search, is_adult]
  );

  const post_params = useMemo(
    () => (self ? post_search_query(self, search, post_type) : {}),
    [search, post_type]
  );
  const [searchPosts, { pending }] = useApiRequestPaged(
    "getPosts",
    post_params,
    true
  );
  const fetchSearch = useCallback(
    (offset: number, limit?: number) => {
      if (!search_ref.current) {
        return Promise.resolve({ data: null });
      }
      return searchPosts(offset, limit);
    },
    [searchPosts]
  );

  // group that was just posted to, for showing group score update
  const [group_id, set_group_id] = useStateIfMounted<string | undefined>(
    undefined
  );
  const new_score = useRef<number | undefined>();
  const { list: groups, dispatch: group_dispatch } = useContext(GROUPS);
  const groups_ref = useValueRef(groups);
  const target_group = groups?.find(g => g.id === group_id);
  // update group score after creating post
  const updateGroup = useCallback(() => {
    if (!target_group) {
      return;
    }
    const score =
      new_score.current == null ? target_group.score : new_score.current;
    if (score != null) {
      group_dispatch.update(g => g.id === target_group.id, {
        ...target_group,
        score,
      });
    }
  }, [target_group?.id, target_group?.score]);
  const bodyProps = useMemo(
    () => ({
      group: target_group,
      updateGroup,
    }),
    [updateGroup, target_group?.id, target_group?.score]
  );

  const onClose = useCallback(() => {
    set_group_id(undefined);
    new_score.current = undefined;
  }, [set_group_id]);

  const [score_modal, set_score_modal_open] = useModal(
    {
      id: "group-score-modal",
      innerClassName: "card card-stack group-score__modal",
      body: GroupScoreModal,
      giveTabFocus: true,
      dismissOnClickBackdrop: true,
      showCloseButton: true,
      bodyProps,
    },
    onClose
  );

  const dispatch_all = useContext(HOME_POSTS).dispatch;
  const { dispatch: dispatch_search } = useContext(HOME_POSTS_SEARCH);
  const onAddPost = useCallback(
    (new_post: Post) => {
      if (!self) {
        return;
      }
      // add to post list after creation
      dispatch_all.add(new_post, "start");
      const { posted_to } = new_post;
      const group_before = groups_ref.current?.find(
        g => g.id === posted_to?.id
      );

      // if score increased as a result of creating the post, show the score modal
      if (
        posted_to &&
        group_before &&
        group_before.score !== posted_to["score"]
      ) {
        set_group_id(posted_to.id);
        new_score.current = posted_to["score"];
        setTimeout(() => set_score_modal_open(true), MODAL_ANIMATION_DELAY);
      }
    },
    [dispatch_all]
  );

  useEffect(() => {
    if (!search) {
      setTimeout(() => {
        dispatch_search.reset(null);
      }, 200);
    }
  }, [search, post_type]);

  const items = useMemo(
    () =>
      ({
        search: (
          <Preact.Fragment>
            <PageHeader />
            <PageBody>
              <HomeActivitySearch
                search={search}
                type={search_type}
                postType={post_type}
                setType={set_type}
                setPostType={set_post_type}
                // @ts-expect-error:
                fetchPosts={fetchSearch}
              />
            </PageBody>
          </Preact.Fragment>
        ),
        all: (
          <Preact.Fragment>
            <PageHeader>
              <WithPostScopeCtx>
                <PostCreatePrompt
                  allowScopeChange={true}
                  onFinish={onAddPost}
                  allowGroupUpdates={allow_group_updates}
                />
              </WithPostScopeCtx>
            </PageHeader>
            <PageBody>
              <HomeActivityBase />
              <HomeSidebar />
            </PageBody>
          </Preact.Fragment>
        ),
      } as const),
    [
      post_id,
      search,
      pending,
      search_type,
      set_search_type,
      post_type,
      set_post_type,
      fetchSearch,
      allow_group_updates,
    ]
  );

  const [search_visible, set_search_visible] = useStateIfMounted(!!search);
  const on_fade_out = useCallback(() => {
    set_search_visible(!!search);
  }, [search, set_search_visible]);

  return (
    <PageContent
      id="home"
      className={`home-page${search_visible ? "" : " has-sidebar"}`}
    >
      <PostIdProvider value={post_id}>
        {is_adult ? (
          <Transition
            page={!!search ? "search" : "all"}
            pages={items}
            onFadeOut={on_fade_out}
          />
        ) : (
          items.all
        )}
      </PostIdProvider>
      {score_modal}
      {is_adult &&
        attachSearchBar(
          <SearchBar
            onSubmit={onSearch}
            placeholder="Search"
            pending={pending}
            value={search}
          />
        )}
    </PageContent>
  );
};
