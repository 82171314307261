import { analytics } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { Goal, WriteGoal } from "@thrive-web/ui-api";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { map_record_to_json_resource, TYPES } from "@thrive-web/core";
import {
  ButtonWithIcon,
  Card,
  DropdownMenu,
  Icon,
  EmptyList,
  LoadingParagraph,
  useRenderDynamicListWithPagedFetch,
  GROUP_DETAIL_LIST_CONTEXTS,
  GROUP_GROUP,
  GroupGoalCreateModal,
  GroupGoalDeleteModal,
  GroupGoalEditModal,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useApiMethod,
  useCrudModal,
  useDocumentTitle,
} from "@thrive-web/ui-hooks";
import {
  display_text,
  make_title,
  group_goals_query_params,
} from "@thrive-web/ui-utils";

import { asSubroute } from "../../asSubroute";

const GroupGoals: Preact.FunctionComponent<RoutePageProps> = () => {
  const group = useContext(GROUP_GROUP);
  useDocumentTitle(
    () => make_title(group?.name ? ["Goals", group.name] : ["Groups"]),
    [group?.name]
  );

  const { list, dispatch } = useContext(GROUP_DETAIL_LIST_CONTEXTS.goals);

  const get_goals_params = useMemo(
    () => (group ? group_goals_query_params(group) : {}),
    [group?.id]
  );
  const get_goals = useApiFetchPaged("getGoals", get_goals_params);

  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (goals, load_more_elem) => (
      <GroupGoalsList goals={goals} loadMoreElem={load_more_elem} />
    ),
    [],
    get_goals,
    undefined,
    { PendingView: GroupGoalsLoading, limit: 15 }
  );

  const createGoal = useApiMethod("createGoal");
  const submitCreateGoal = useCallback(
    (new_goal: WriteGoal) =>
      createGoal({
        body: {
          data: map_record_to_json_resource(
            { ...new_goal, group: { id: group?.id } } as Goal,
            TYPES["Goal"]
          ),
        },
      }).then(result => {
        analytics.log_event(analytics.EVENTS.create_goal_saved);
        dispatch.add(result.data);
        return result;
      }),
    [createGoal]
  );

  const createBodyProps = useMemo(
    () => ({ onSubmit: submitCreateGoal }),
    [submitCreateGoal]
  );
  const [createGoalsModal, openCreateGoalsModal] = useCrudModal(
    "create-goals-modal",
    GroupGoalCreateModal,
    createBodyProps
  );

  if (!group) {
    return null;
  }

  return (
    <div className="page-tab group-goals-page">
      <div className="page-tab__section">
        <div className="page-tab__top">
          <div className="page-tab__section__title">Group Goals</div>
          <ButtonWithIcon
            icon="add"
            side="left"
            className="filled gray"
            onClick={() => {
              analytics.log_event(analytics.EVENTS.create_goal);
              openCreateGoalsModal({ id: "" });
            }}
          >
            Add a Goal
          </ButtonWithIcon>
        </div>
        <div className="page-tab__record-list group-goals__list">{content}</div>
      </div>
      {createGoalsModal}
    </div>
  );
};

const GroupGoalsList: Preact.FunctionComponent<{
  goals: readonly Goal[];
  loadMoreElem?: Preact.VNode | null;
}> = ({ goals, loadMoreElem }) => {
  // handle the edit/delete modals here rather than having 2 modals for each list item
  const updateGoals = useContext(GROUP_DETAIL_LIST_CONTEXTS.goals).dispatch;

  const updateGoal = useApiMethod("updateGoal");
  const submitUpdateGoal = useCallback(
    (patched: Goal) => {
      return updateGoal(patched.id, {
        body: {
          data: map_record_to_json_resource(patched, TYPES["Goal"]),
        },
      }).then(result => {
        updateGoals.update(g => g.id === patched.id, result.data);
        return result;
      });
    },
    [updateGoal]
  );

  const editBodyProps = useMemo(
    () => ({ onSubmit: submitUpdateGoal }),
    [submitUpdateGoal]
  );
  const [editGoalModal, set_edit_target] = useCrudModal(
    "edit-goals-modal",
    GroupGoalEditModal,
    editBodyProps
  );

  const deleteGoal = useApiMethod("deleteGoal");
  const submitDeleteGoal = useCallback(
    (deleted: Goal) =>
      deleteGoal(deleted.id).then(res => {
        updateGoals.remove(g => g.id === deleted.id);
        return res;
      }),
    [deleteGoal, updateGoals]
  );

  const deleteBodyProps = useMemo(
    () => ({ onSubmit: submitDeleteGoal }),
    [submitDeleteGoal]
  );
  const [deleteGoalModal, set_delete_target] = useCrudModal(
    "delete-goals-modal",
    GroupGoalDeleteModal,
    deleteBodyProps
  );

  if (!goals) {
    return null;
  }

  if (goals.length === 0) {
    return (
      <EmptyList>
        <p>There are no goals for this group yet.</p>
      </EmptyList>
    );
  }

  return (
    <Preact.Fragment>
      <ul>
        {goals.map((goal, i) => (
          <li key={goal.id} className="goal-list__item">
            <Card>
              <div className="goal-list__item__icon">
                <Icon name="goal" iconSize="sm" />
              </div>
              <div className="goal-list__item__text">
                <div className="goal-list__item__title">
                  {display_text(goal.name)}
                </div>
                <div className="goal-list__item__description">
                  {display_text(goal.description)}
                </div>
              </div>
              <div className="goal-list__item__actions">
                <DropdownMenu
                  items={[
                    <ButtonWithIcon
                      icon="remove"
                      side="left"
                      onClick={() => set_delete_target(goal)}
                      className="filled pill gray"
                    >
                      Delete Goal
                    </ButtonWithIcon>,
                    <ButtonWithIcon
                      icon="edit"
                      side="left"
                      onClick={() => set_edit_target(goal)}
                      className="filled pill gray"
                    >
                      Edit Goal
                    </ButtonWithIcon>,
                  ]}
                  listClassName="card pill-card"
                  buttonClassName="filled transparent all-gray"
                />
              </div>
            </Card>
          </li>
        ))}
        {loadMoreElem && (
          <li className="goal-list__load-more">{loadMoreElem}</li>
        )}
      </ul>
      {editGoalModal}
      {deleteGoalModal}
    </Preact.Fragment>
  );
};

export const GroupGoalsPage = asSubroute(GroupGoals);

export const GroupGoalsLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(4).fill(0), []);
  return (
    <ul className="loading-item__list">
      {items.map((_, i) => (
        <li key={i} className="goal-list__item loading-item">
          <Card>
            <div className="goal-list__item__icon">
              <Icon name="goal" iconSize="sm" />
            </div>
            <div className="goal-list__item__text">
              <div className="loading-item__title loading-item__shaded" />
              <LoadingParagraph maxLines={5} />
            </div>
            <div className="goal-list__item__actions">
              <div className="button with-icon icon-only loading-item__shaded--light" />
            </div>
          </Card>
        </li>
      ))}
    </ul>
  );
};
