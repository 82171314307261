import { get_url_for_entity } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { Group } from "@thrive-web/ui-api";
import {
  AppLink,
  DefaultPendingView,
  GROUP_DETAIL_LIST_CONTEXTS,
  SidebarSection,
} from "@thrive-web/ui-components";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { useApiRequest } from "@thrive-web/ui-hooks";
import { todo_query } from "./utils";
import { GroupSidebarTodoItem } from "./GroupSidebarTodoItem";

export const GroupSidebarTodos: Preact.FunctionComponent<{ group: Group }> = ({
  group,
}) => {
  const { list, dispatch } = useContext(GROUP_DETAIL_LIST_CONTEXTS.todos);

  const todos_params = useMemo(
    () => ({
      query: {
        ...todo_query(group),
        offset: 0,
        limit: 5,
        include_count: true,
      },
    }),
    [group?.id]
  );
  const [getTodos, { pending }] = useApiRequest("getTodos", todos_params);

  useEffect(() => {
    if (!list || list.some(t => t.group?.id !== group.id)) {
      getTodos()
        .then(({ data }) => {
          dispatch.reset(data);
        })
        .catch(err => {
          if (err.code !== "api/request-already-sent") {
            throw err;
          }
        });
    }
  }, [list, group?.id]);

  // only display first 5 incomplete todos in the sidebar
  const filtered = useMemo(
    () => (list ? list.filter(t => !t.completed) : []).slice(0, 5),
    [list]
  );

  if (list && !pending && filtered.length === 0) {
    return null;
  }

  const url = get_url_for_entity(group);
  let content;

  if (!list || pending) {
    content = <DefaultPendingView />;
  } else {
    content = (
      <div className="group-sidebar__todo__list">
        {filtered.map(todo => (
          <GroupSidebarTodoItem key={todo.id} todo={todo} group={group} />
        ))}
      </div>
    );
  }

  return (
    <SidebarSection
      key="goals"
      name="Due Soon"
      moreLink={<AppLink href={`${url}/todos`}>View All</AppLink>}
    >
      {content}
    </SidebarSection>
  );
};
