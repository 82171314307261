import * as Preact from "preact";
import { Group, Post } from "@thrive-web/ui-api";
import { GroupScoreModal } from "~/view/components";
import {
  ActivityFeed,
  ACTIVITY_FEED_POST_ID,
  asSubroute,
  PostCreatePrompt,
  useRenderDynamicListWithPagedFetch,
  PostListLoading,
  GROUP_DETAIL_LIST_CONTEXTS,
  GROUP_DISPATCH,
  GROUP_GROUP,
  GroupPermCheck,
  EmptyList,
} from "@thrive-web/ui-components";
import {
  useApiFetchPaged,
  useAppUser,
  useDocumentTitle,
  useModal,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { get_url_for_entity, make_title } from "@thrive-web/ui-utils";

import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { ComplexKeysOf } from "@thrive-web/core";

const POST_DETAIL_PATH = "/groups/:id/activity/:post";

const PostIdProvider = ACTIVITY_FEED_POST_ID.Provider;

const GroupActivity: Preact.FunctionComponent<
  RoutePageProps & { post_id?: string }
> = ({ post_id }) => {
  const self = useAppUser();
  const group = useContext(GROUP_GROUP) || ({} as Group);
  useDocumentTitle(
    () => make_title(group?.name ? ["Activity", group.name] : ["Groups"]),
    [group?.name]
  );
  const update_group = useContext(GROUP_DISPATCH);
  const group_ref = useValueRef(group);
  // updated score after an interaction
  const new_score = useRef(group?.score);

  // user can interact if they're a member of the group
  const can_interact = useMemo(
    () =>
      !!group.has_member?.some(u => u.id === self?.id)
        ? () => true
        : () => false,
    [group?.has_member, self?.id]
  );

  const req_params = useMemo(
    () => ({
      query: {
        include: [
          "has_touchpoint",
          "has_reaction",
          "has_expense",
          "mood",
          "photo",
          "event.Event:cover_image",
          "created_by.User:profile_picture",
          "has_tagged_user.User:profile_picture",
          "has_comment.Comment:created_by.User:profile_picture",
        ] as ComplexKeysOf<Post>[],
        filter: [
          ["=", ["this", "Post:posted_to"], ["id", group.id as string]],
        ] as const,
        sort: [{ by: "created_at" as const, dir: "desc" as const }],
      },
    }),
    [group?.id]
  );
  const get_posts_req = useApiFetchPaged("getPosts", req_params);
  const get_posts = useCallback(
    (offset: number, limit?: number) =>
      get_posts_req(offset, limit).then(result => ({
        ...result,
        data: result.data.map(p => ({ ...p, posted_to: group })),
      })),
    [get_posts_req, group]
  );

  // path to return to after exiting a post detail modal
  const detailExitPath = useMemo(
    () => `${get_url_for_entity(group)}/activity`,
    [group]
  );
  const { list, dispatch } = useContext(GROUP_DETAIL_LIST_CONTEXTS.posts);
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more) => (
      <ActivityFeed
        posts={result}
        detailPath={POST_DETAIL_PATH}
        detailExitPath={detailExitPath}
        dynamicListCtx={GROUP_DETAIL_LIST_CONTEXTS.posts}
        loadMoreElem={load_more}
        canInteract={can_interact}
        emptyView={
          <EmptyList>
            <p>
              This group has no activity. Post something to get the conversation
              started!
            </p>
          </EmptyList>
        }
      />
    ),
    [group],
    get_posts,
    undefined,
    { PendingView: PostListLoading }
  );

  const bodyProps = useMemo(
    () => ({
      group,
      updateGroup: () =>
        update_group("group", {
          ...group_ref.current,
          score:
            new_score.current == null
              ? group_ref.current.score
              : new_score.current,
        }),
    }),
    [update_group, group?.id, group?.score]
  );

  const [score_modal, set_score_modal_open] = useModal({
    id: "group-score-modal",
    innerClassName: "card card-stack group-score__modal",
    body: GroupScoreModal,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
    showCloseButton: true,
    bodyProps,
  });

  const onAddPost = useCallback(
    (new_post: Post) => {
      new_score.current = new_post.posted_to?.["score"];
      dispatch.add(new_post, "start");
      if (
        group_ref.current?.has_relationship_management &&
        // if the score has changed, show the score modal
        group_ref.current?.score !== new_score.current
      ) {
        set_score_modal_open(true);
      }
    },
    [dispatch, set_score_modal_open]
  );

  if (!group || !group.id) {
    return null;
  }

  return (
    <div className="group-activity__content">
      <GroupPermCheck role="has_member">
        <div className="group-activity__create-post">
          <PostCreatePrompt defaultScope={group} onFinish={onAddPost} />
        </div>
      </GroupPermCheck>
      <PostIdProvider value={post_id}>{content}</PostIdProvider>
      {score_modal}
    </div>
  );
};

export const GroupActivityPage = asSubroute(GroupActivity);
